<template>
  <v-container fluid class="pa-5">
    <div class="mt-2">
      <!-- {{ message }} -->
      <v-row>
        <v-col cols="12" lg="6" sm="6" md="6">
          <v-card
            color="primary lighten-1"
            dark
            class="d-flex flex-column align-center"
            elevation-7
          >
            <v-card-title class="justify-center">
              <p class="text-h1 font-weight-black">NON RACIKAN</p>
            </v-card-title>
            <v-card-subtitle class="text-center">
              <h3 class="text-h3 font-weight-black">{{ vmodeltanggal }}</h3>
            </v-card-subtitle>
            <v-img
              class="rounded-circle mb-3 pb-2"
              width="340"
              height="340"
              elevation-10
              src="https://emr.rs-syafira.com/img/drug_racikan.png"
            ></v-img>
            <v-card-text>
              <h1 class="text-h1 font-weight-black text-center">
                {{ nonRacikan.antrian }}
              </h1>
              <p class="text-h5 font-weight-black text-center">
                {{ nonRacikan.status }}
              </p>
              <h4 class="text-h4 font-weight-black display-2 text-center">
                {{ nonRacikan.nama }}
              </h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" sm="6" md="6">
          <v-card
            color="purple lighten-1"
            dark
            class="d-flex flex-column align-center"
            elevation-7
          >
            <v-card-title class="justify-center">
              <p class="text-h1 font-weight-black">RACIKAN</p>
            </v-card-title>
            <v-card-subtitle class="text-center"
              ><h3 class="text-h3 font-weight-black">
                {{ vmodeltanggal }}
              </h3></v-card-subtitle
            >
            <v-img
              class="rounded-circle mb-3 pb-2"
              width="340"
              elevation-10
              height="340"
              src="https://emr.rs-syafira.com/img/drug_non_racikan.png"
            ></v-img>
            <v-card-text>
              <h1 class="text-h1 font-weight-black text-center">
                {{ Racikan.antrian }}
              </h1>
              <p class="text-h5 font-weight-black text-center">
                {{ Racikan.status }}
              </p>
              <h4 class="text-h4 font-weight-black display-2 text-center">
                {{ Racikan.nama }}
              </h4>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'
export default {
  name: 'LayarFarmasi',
  data () {
    return {
      vmodelpanggilan: '',
      vmodelNamaDokter: '',
      vmodeltanggal: '',
      vmodelNama: '',
      message: '',
      progress: false,
      namaPoli: '',
      random_id:
        'client-' + Math.round(Math.random(100000000, 1000000000) * 1000000000),
      client: '',
      is_connect: false,
      locationId: '',
      searchDokter: '',
      itemsNamaDokter: [],
      nonRacikan: {
        nama: '',
        antrian: '',
        status: '----'
      },
      Racikan: {
        nama: '',
        antrian: '',
        status: '----'
      },
      // selected: '',
      item: [
        { id: 34, text: 'POLI TULIP' },
        { id: 35, text: 'POLI BOUGENVILE' },
        { id: 36, text: 'POLI ANGGREK' },
        { id: 37, text: 'POLI EDELWIS' },
        { id: 68, text: 'POLI EKSEKUTIF' }
      ],
      headers: [
        // Define your table headers here
        {
          text: 'Poli/Ruangan',
          value: 'sub_unit_name',
          width: '200px',
          align: 'left'
        },
        {
          text: 'Nama Pasien',
          value: 'patient_name',
          width: '200px',
          align: 'left'
        },
        {
          text: 'Aksi',
          value: 'folio_id',
          width: '100px',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'status_panggilan',
          width: '10px',
          align: 'center'
        }
      ],
      items: [],
      search: '',
      tglPelayanan: '',
      loading5: false,
      search2: '',
      total: 0,
      startTime: null,
      elapsedTime: '',
      intervalId: null,
      page: 1,
      limit: 40,
      rbulan: '',
      loading: false,
      text: '',
      language: 'id-ID',
      rate: 0.9,
      voices: [],
      selectedVoice: null
    }
  },
  created () {
    // this.ubahlayar()
    this.namaPoli = this.$route.query.namaPoli
    this.locationId = this.$route.query.locationId
    this.vmodelpanggilan = { id: this.locationId, text: this.namaPoli }
    this.jamSekarang()
    // eslint-disable-next-line no-undef
  },

  mounted () {
    setTimeout(() => {
      const randomId =
        'client-' + Math.round(Math.random() * 900000000 + 100000000)
      // eslint-disable-next-line no-undef
      this.client = new Paho.Client('broker.emqx.io', 8084, randomId)
      this.client.onConnectionLost = this.onConnectionLost
      this.client.onMessageArrived = this.onMessageArrived
      this.connectClient()
      // eslint-disable-next-line no-undef
      $('.v-text-field__details').removeClass('v-text-field__details')
      // eslint-disable-next-line no-undef
      $('.v-messages').removeClass('v-messages theme--light')
    }, 100)
  },
  methods: {
    connectClient () {
      console.log('Connecting to MQTT broker...')
      this.connectionStatus = 'Connecting...'
      this.client.connect({
        onSuccess: this.onConnect,
        onFailure: this.onFailure,
        useSSL: true
      })
    },
    onConnect () {
      this.isConnect = true
      this.connectionStatus = 'Connected'
      console.log('Connected to MQTT broker')
      this.client.subscribe('farmasitest')
    },
    onFailure (errorMessage) {
      this.isConnect = false
      this.connectionStatus = 'Connection Failed'
      console.error(
        'Failed to connect to MQTT broker:',
        errorMessage.errorMessage
      )
      setTimeout(this.connectClient, 5000) // Retry every 5 seconds
    },
    onConnectionLost (responseObject) {
      if (responseObject.errorCode !== 0) {
        console.error('Connection lost:', responseObject.errorMessage)
        this.isConnect = false
        this.connectionStatus = 'Connection Lost'
        this.connectClient() // Retry connecting immediately
      }
    },
    onMessageArrived (message) {
      // this.messages.push(message.payloadString)
      // eslint-disable-next-line no-unused-vars
      const data = JSON.parse(message.payloadString)
      // eslint-disable-next-line no-unused-vars
      const locid = this.$route.query.loc_id
      // eslint-disable-next-line no-unused-vars
      var array = ''
      if (locid === 'farmasi_sentral') {
        array = ['farmasi_sentral', 'farmasi_sentral_racikan']
      } else if (locid === 'farmasi_edelweis') {
        array = ['farmasi_edelweis', 'farmasi_edelweis_racikan']
      } else if (locid === 'farmasi_anggrek') {
        array = ['farmasi_anggrek', 'farmasi_anggrek_racikan']
      }
      // console.log(array.includes(data.nama_loket))

      this.message = data
      console.log(data)
      if (
        data.perintah === 'SET_PANGGIL_FARMASI_V2' &&
        array.includes(data.nama_loket)
      ) {
        console.log('onMessageArrived: ' + message.payloadString)

        if (data.is_racikan === 0) {
          this.nonRacikan.nama = data.patient_name
          this.nonRacikan.antrian = data.no_antrian
          this.nonRacikan.status = data.status
        } else {
          this.Racikan.nama = data.patient_name
          this.Racikan.antrian = data.no_antrian
          this.Racikan.status = data.status
        }

        // Get the text to be spoken
        // eslint-disable-next-line no-unused-vars
        const text = data.text
        // const rate = 0.5
        // Get the selected language
        // eslint-disable-next-line no-unused-vars
        const language = 'id-ID'
        // this.text = text
        if ('speechSynthesis' in window) {
          this.populateVoices()
          window.speechSynthesis.onvoiceschanged = this.populateVoices()
        } else {
          alert('Sorry, your browser does not support text to speech!')
        }
        this.speak(text)
        // Check if the browser supports the Web Speech API
        // if ('speechSynthesis' in window) {
        //   // Create a new SpeechSynthesisUtterance object
        //   const utterance = new SpeechSynthesisUtterance(text)
        //   // Filter voices to find a female English voice
        //   const voices = window.speechSynthesis.getVoices()
        //   const femaleVoice = voices.find(
        //     (voice) => voice.lang === language && voice.gender === 'female'
        //   )

        //   // Set the language
        //   utterance.lang = language
        //   // utterance.rate = rate
        //   utterance.rate = 0.9
        //   utterance.voice = femaleVoice
        //   utterance.pause = 700 // Example: 500ms pause between sentences

        //   // Speak the text
        //   window.speechSynthesis.speak(utterance)
        // } else {
        //   alert('Sorry, your browser does not support text to speech!')
        // }
      }
    },
    populateVoices () {
      this.voices = window.speechSynthesis.getVoices()
      this.selectedVoice = this.getVoice('Microsoft Zira Desktop - English (United States)')

      if (!this.selectedVoice) {
        console.warn('Female English voice not found.')
      }
    },
    getVoice (voiceName) {
      return this.voices.find(voice => voice.name === voiceName)
    },
    speak (text) {
      const utterance = new SpeechSynthesisUtterance(text)
      utterance.lang = this.language
      utterance.rate = this.rate
      utterance.voice = this.selectedVoice

      // Speak the text
      window.speechSynthesis.speak(utterance)
    },
    stopSpeaking () {
      window.speechSynthesis.cancel()
    },
    jamSekarang () {
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      // const formattedToday = dd + '/' + mm + '/' + yyyy
      const todays = dd + '-' + mm + '-' + yyyy
      // this.tglSekarang = formattedToday
      this.vmodeltanggal = todays
    }
  },
  computed: {
    // searchDokter () {
    //   return true
    // }
  }
}
</script>
<style scoped>
.rounded-circle {
  border-radius: 50%;
  object-fit: cover; /* ensures the image fits within the circle without distortion */
}
</style>
